/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPage = /* GraphQL */ `
  mutation CreatePage(
    $input: CreatePageInput!
    $condition: ModelPageConditionInput
  ) {
    createPage(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      pages {
        items {
          id
          name
          createdAt
          updatedAt
          pages {
            nextToken
          }
          collections {
            nextToken
          }
        }
        nextToken
      }
      collections {
        items {
          id
          name
          index
          collectionPageId
          createdAt
          updatedAt
          page {
            id
            name
            createdAt
            updatedAt
          }
          products {
            nextToken
          }
          materials {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const updatePage = /* GraphQL */ `
  mutation UpdatePage(
    $input: UpdatePageInput!
    $condition: ModelPageConditionInput
  ) {
    updatePage(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      pages {
        items {
          id
          name
          createdAt
          updatedAt
          pages {
            nextToken
          }
          collections {
            nextToken
          }
        }
        nextToken
      }
      collections {
        items {
          id
          name
          index
          collectionPageId
          createdAt
          updatedAt
          page {
            id
            name
            createdAt
            updatedAt
          }
          products {
            nextToken
          }
          materials {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const deletePage = /* GraphQL */ `
  mutation DeletePage(
    $input: DeletePageInput!
    $condition: ModelPageConditionInput
  ) {
    deletePage(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      pages {
        items {
          id
          name
          createdAt
          updatedAt
          pages {
            nextToken
          }
          collections {
            nextToken
          }
        }
        nextToken
      }
      collections {
        items {
          id
          name
          index
          collectionPageId
          createdAt
          updatedAt
          page {
            id
            name
            createdAt
            updatedAt
          }
          products {
            nextToken
          }
          materials {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const createCollection = /* GraphQL */ `
  mutation CreateCollection(
    $input: CreateCollectionInput!
    $condition: ModelCollectionConditionInput
  ) {
    createCollection(input: $input, condition: $condition) {
      id
      name
      index
      collectionPageId
      createdAt
      updatedAt
      page {
        id
        name
        createdAt
        updatedAt
        pages {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        collections {
          items {
            id
            name
            index
            collectionPageId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      products {
        items {
          id
          productName
          collectionId
          createdAt
          updatedAt
          collection {
            id
            name
            index
            collectionPageId
            createdAt
            updatedAt
          }
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      materials {
        items {
          id
          materialName
          collectionId
          createdAt
          updatedAt
          collection {
            id
            name
            index
            collectionPageId
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const updateCollection = /* GraphQL */ `
  mutation UpdateCollection(
    $input: UpdateCollectionInput!
    $condition: ModelCollectionConditionInput
  ) {
    updateCollection(input: $input, condition: $condition) {
      id
      name
      index
      collectionPageId
      createdAt
      updatedAt
      page {
        id
        name
        createdAt
        updatedAt
        pages {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        collections {
          items {
            id
            name
            index
            collectionPageId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      products {
        items {
          id
          productName
          collectionId
          createdAt
          updatedAt
          collection {
            id
            name
            index
            collectionPageId
            createdAt
            updatedAt
          }
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      materials {
        items {
          id
          materialName
          collectionId
          createdAt
          updatedAt
          collection {
            id
            name
            index
            collectionPageId
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteCollection = /* GraphQL */ `
  mutation DeleteCollection(
    $input: DeleteCollectionInput!
    $condition: ModelCollectionConditionInput
  ) {
    deleteCollection(input: $input, condition: $condition) {
      id
      name
      index
      collectionPageId
      createdAt
      updatedAt
      page {
        id
        name
        createdAt
        updatedAt
        pages {
          items {
            id
            name
            createdAt
            updatedAt
          }
          nextToken
        }
        collections {
          items {
            id
            name
            index
            collectionPageId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      products {
        items {
          id
          productName
          collectionId
          createdAt
          updatedAt
          collection {
            id
            name
            index
            collectionPageId
            createdAt
            updatedAt
          }
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      materials {
        items {
          id
          materialName
          collectionId
          createdAt
          updatedAt
          collection {
            id
            name
            index
            collectionPageId
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const createCollectionProduct = /* GraphQL */ `
  mutation CreateCollectionProduct(
    $input: CreateCollectionProductInput!
    $condition: ModelCollectionProductConditionInput
  ) {
    createCollectionProduct(input: $input, condition: $condition) {
      id
      productName
      collectionId
      createdAt
      updatedAt
      collection {
        id
        name
        index
        collectionPageId
        createdAt
        updatedAt
        page {
          id
          name
          createdAt
          updatedAt
          pages {
            nextToken
          }
          collections {
            nextToken
          }
        }
        products {
          items {
            id
            productName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        materials {
          items {
            id
            materialName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      product {
        id
        name
        description
        year
        designer
        createdAt
        updatedAt
        collections {
          items {
            id
            productName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        thumbnail {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        images {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        cad {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        spec {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        homepage {
          id
          index
          type
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          homepage {
            id
            name
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const updateCollectionProduct = /* GraphQL */ `
  mutation UpdateCollectionProduct(
    $input: UpdateCollectionProductInput!
    $condition: ModelCollectionProductConditionInput
  ) {
    updateCollectionProduct(input: $input, condition: $condition) {
      id
      productName
      collectionId
      createdAt
      updatedAt
      collection {
        id
        name
        index
        collectionPageId
        createdAt
        updatedAt
        page {
          id
          name
          createdAt
          updatedAt
          pages {
            nextToken
          }
          collections {
            nextToken
          }
        }
        products {
          items {
            id
            productName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        materials {
          items {
            id
            materialName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      product {
        id
        name
        description
        year
        designer
        createdAt
        updatedAt
        collections {
          items {
            id
            productName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        thumbnail {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        images {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        cad {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        spec {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        homepage {
          id
          index
          type
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          homepage {
            id
            name
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const deleteCollectionProduct = /* GraphQL */ `
  mutation DeleteCollectionProduct(
    $input: DeleteCollectionProductInput!
    $condition: ModelCollectionProductConditionInput
  ) {
    deleteCollectionProduct(input: $input, condition: $condition) {
      id
      productName
      collectionId
      createdAt
      updatedAt
      collection {
        id
        name
        index
        collectionPageId
        createdAt
        updatedAt
        page {
          id
          name
          createdAt
          updatedAt
          pages {
            nextToken
          }
          collections {
            nextToken
          }
        }
        products {
          items {
            id
            productName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        materials {
          items {
            id
            materialName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      product {
        id
        name
        description
        year
        designer
        createdAt
        updatedAt
        collections {
          items {
            id
            productName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        thumbnail {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        images {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        cad {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        spec {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        homepage {
          id
          index
          type
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          homepage {
            id
            name
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      name
      description
      year
      designer
      createdAt
      updatedAt
      collections {
        items {
          id
          productName
          collectionId
          createdAt
          updatedAt
          collection {
            id
            name
            index
            collectionPageId
            createdAt
            updatedAt
          }
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      thumbnail {
        id
        name
        url
        index
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        material {
          id
          name
          description
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
        }
        aboutus {
          id
          name
          title
          subtitle
          description
          createdAt
          updatedAt
          images {
            nextToken
          }
        }
      }
      images {
        items {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      cad {
        id
        name
        url
        index
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        material {
          id
          name
          description
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
        }
        aboutus {
          id
          name
          title
          subtitle
          description
          createdAt
          updatedAt
          images {
            nextToken
          }
        }
      }
      spec {
        id
        name
        url
        index
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        material {
          id
          name
          description
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
        }
        aboutus {
          id
          name
          title
          subtitle
          description
          createdAt
          updatedAt
          images {
            nextToken
          }
        }
      }
      homepage {
        id
        index
        type
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        homepage {
          id
          name
          createdAt
          updatedAt
          items {
            nextToken
          }
        }
      }
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      name
      description
      year
      designer
      createdAt
      updatedAt
      collections {
        items {
          id
          productName
          collectionId
          createdAt
          updatedAt
          collection {
            id
            name
            index
            collectionPageId
            createdAt
            updatedAt
          }
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      thumbnail {
        id
        name
        url
        index
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        material {
          id
          name
          description
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
        }
        aboutus {
          id
          name
          title
          subtitle
          description
          createdAt
          updatedAt
          images {
            nextToken
          }
        }
      }
      images {
        items {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      cad {
        id
        name
        url
        index
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        material {
          id
          name
          description
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
        }
        aboutus {
          id
          name
          title
          subtitle
          description
          createdAt
          updatedAt
          images {
            nextToken
          }
        }
      }
      spec {
        id
        name
        url
        index
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        material {
          id
          name
          description
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
        }
        aboutus {
          id
          name
          title
          subtitle
          description
          createdAt
          updatedAt
          images {
            nextToken
          }
        }
      }
      homepage {
        id
        index
        type
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        homepage {
          id
          name
          createdAt
          updatedAt
          items {
            nextToken
          }
        }
      }
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      name
      description
      year
      designer
      createdAt
      updatedAt
      collections {
        items {
          id
          productName
          collectionId
          createdAt
          updatedAt
          collection {
            id
            name
            index
            collectionPageId
            createdAt
            updatedAt
          }
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      thumbnail {
        id
        name
        url
        index
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        material {
          id
          name
          description
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
        }
        aboutus {
          id
          name
          title
          subtitle
          description
          createdAt
          updatedAt
          images {
            nextToken
          }
        }
      }
      images {
        items {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      cad {
        id
        name
        url
        index
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        material {
          id
          name
          description
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
        }
        aboutus {
          id
          name
          title
          subtitle
          description
          createdAt
          updatedAt
          images {
            nextToken
          }
        }
      }
      spec {
        id
        name
        url
        index
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        material {
          id
          name
          description
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
        }
        aboutus {
          id
          name
          title
          subtitle
          description
          createdAt
          updatedAt
          images {
            nextToken
          }
        }
      }
      homepage {
        id
        index
        type
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        homepage {
          id
          name
          createdAt
          updatedAt
          items {
            nextToken
          }
        }
      }
    }
  }
`;
export const createCollectionMaterial = /* GraphQL */ `
  mutation CreateCollectionMaterial(
    $input: CreateCollectionMaterialInput!
    $condition: ModelCollectionMaterialConditionInput
  ) {
    createCollectionMaterial(input: $input, condition: $condition) {
      id
      materialName
      collectionId
      createdAt
      updatedAt
      collection {
        id
        name
        index
        collectionPageId
        createdAt
        updatedAt
        page {
          id
          name
          createdAt
          updatedAt
          pages {
            nextToken
          }
          collections {
            nextToken
          }
        }
        products {
          items {
            id
            productName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        materials {
          items {
            id
            materialName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      material {
        id
        name
        description
        createdAt
        updatedAt
        collections {
          items {
            id
            materialName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        thumbnail {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        images {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        cad {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        spec {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const updateCollectionMaterial = /* GraphQL */ `
  mutation UpdateCollectionMaterial(
    $input: UpdateCollectionMaterialInput!
    $condition: ModelCollectionMaterialConditionInput
  ) {
    updateCollectionMaterial(input: $input, condition: $condition) {
      id
      materialName
      collectionId
      createdAt
      updatedAt
      collection {
        id
        name
        index
        collectionPageId
        createdAt
        updatedAt
        page {
          id
          name
          createdAt
          updatedAt
          pages {
            nextToken
          }
          collections {
            nextToken
          }
        }
        products {
          items {
            id
            productName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        materials {
          items {
            id
            materialName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      material {
        id
        name
        description
        createdAt
        updatedAt
        collections {
          items {
            id
            materialName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        thumbnail {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        images {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        cad {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        spec {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const deleteCollectionMaterial = /* GraphQL */ `
  mutation DeleteCollectionMaterial(
    $input: DeleteCollectionMaterialInput!
    $condition: ModelCollectionMaterialConditionInput
  ) {
    deleteCollectionMaterial(input: $input, condition: $condition) {
      id
      materialName
      collectionId
      createdAt
      updatedAt
      collection {
        id
        name
        index
        collectionPageId
        createdAt
        updatedAt
        page {
          id
          name
          createdAt
          updatedAt
          pages {
            nextToken
          }
          collections {
            nextToken
          }
        }
        products {
          items {
            id
            productName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        materials {
          items {
            id
            materialName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      material {
        id
        name
        description
        createdAt
        updatedAt
        collections {
          items {
            id
            materialName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        thumbnail {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        images {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        cad {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        spec {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const createMaterial = /* GraphQL */ `
  mutation CreateMaterial(
    $input: CreateMaterialInput!
    $condition: ModelMaterialConditionInput
  ) {
    createMaterial(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
      collections {
        items {
          id
          materialName
          collectionId
          createdAt
          updatedAt
          collection {
            id
            name
            index
            collectionPageId
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      thumbnail {
        id
        name
        url
        index
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        material {
          id
          name
          description
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
        }
        aboutus {
          id
          name
          title
          subtitle
          description
          createdAt
          updatedAt
          images {
            nextToken
          }
        }
      }
      images {
        items {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      cad {
        id
        name
        url
        index
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        material {
          id
          name
          description
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
        }
        aboutus {
          id
          name
          title
          subtitle
          description
          createdAt
          updatedAt
          images {
            nextToken
          }
        }
      }
      spec {
        id
        name
        url
        index
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        material {
          id
          name
          description
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
        }
        aboutus {
          id
          name
          title
          subtitle
          description
          createdAt
          updatedAt
          images {
            nextToken
          }
        }
      }
    }
  }
`;
export const updateMaterial = /* GraphQL */ `
  mutation UpdateMaterial(
    $input: UpdateMaterialInput!
    $condition: ModelMaterialConditionInput
  ) {
    updateMaterial(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
      collections {
        items {
          id
          materialName
          collectionId
          createdAt
          updatedAt
          collection {
            id
            name
            index
            collectionPageId
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      thumbnail {
        id
        name
        url
        index
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        material {
          id
          name
          description
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
        }
        aboutus {
          id
          name
          title
          subtitle
          description
          createdAt
          updatedAt
          images {
            nextToken
          }
        }
      }
      images {
        items {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      cad {
        id
        name
        url
        index
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        material {
          id
          name
          description
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
        }
        aboutus {
          id
          name
          title
          subtitle
          description
          createdAt
          updatedAt
          images {
            nextToken
          }
        }
      }
      spec {
        id
        name
        url
        index
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        material {
          id
          name
          description
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
        }
        aboutus {
          id
          name
          title
          subtitle
          description
          createdAt
          updatedAt
          images {
            nextToken
          }
        }
      }
    }
  }
`;
export const deleteMaterial = /* GraphQL */ `
  mutation DeleteMaterial(
    $input: DeleteMaterialInput!
    $condition: ModelMaterialConditionInput
  ) {
    deleteMaterial(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
      collections {
        items {
          id
          materialName
          collectionId
          createdAt
          updatedAt
          collection {
            id
            name
            index
            collectionPageId
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      thumbnail {
        id
        name
        url
        index
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        material {
          id
          name
          description
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
        }
        aboutus {
          id
          name
          title
          subtitle
          description
          createdAt
          updatedAt
          images {
            nextToken
          }
        }
      }
      images {
        items {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      cad {
        id
        name
        url
        index
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        material {
          id
          name
          description
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
        }
        aboutus {
          id
          name
          title
          subtitle
          description
          createdAt
          updatedAt
          images {
            nextToken
          }
        }
      }
      spec {
        id
        name
        url
        index
        message {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        product {
          id
          name
          description
          year
          designer
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          homepage {
            id
            index
            type
            createdAt
            updatedAt
          }
        }
        material {
          id
          name
          description
          createdAt
          updatedAt
          collections {
            nextToken
          }
          thumbnail {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          images {
            nextToken
          }
          cad {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          spec {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
        }
        aboutus {
          id
          name
          title
          subtitle
          description
          createdAt
          updatedAt
          images {
            nextToken
          }
        }
      }
    }
  }
`;
export const createAsset = /* GraphQL */ `
  mutation CreateAsset(
    $input: CreateAssetInput!
    $condition: ModelAssetConditionInput
  ) {
    createAsset(input: $input, condition: $condition) {
      id
      name
      url
      index
      message {
        id
        recipientContacts {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientCompanys {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientEmployees {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        toOthers
        ccOthers
        bccOthers
        subject
        body
        createdAt
        updatedAt
        attachments {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        from {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      createdAt
      updatedAt
      product {
        id
        name
        description
        year
        designer
        createdAt
        updatedAt
        collections {
          items {
            id
            productName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        thumbnail {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        images {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        cad {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        spec {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        homepage {
          id
          index
          type
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          homepage {
            id
            name
            createdAt
            updatedAt
          }
        }
      }
      material {
        id
        name
        description
        createdAt
        updatedAt
        collections {
          items {
            id
            materialName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        thumbnail {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        images {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        cad {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        spec {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
      }
      aboutus {
        id
        name
        title
        subtitle
        description
        createdAt
        updatedAt
        images {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateAsset = /* GraphQL */ `
  mutation UpdateAsset(
    $input: UpdateAssetInput!
    $condition: ModelAssetConditionInput
  ) {
    updateAsset(input: $input, condition: $condition) {
      id
      name
      url
      index
      message {
        id
        recipientContacts {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientCompanys {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientEmployees {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        toOthers
        ccOthers
        bccOthers
        subject
        body
        createdAt
        updatedAt
        attachments {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        from {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      createdAt
      updatedAt
      product {
        id
        name
        description
        year
        designer
        createdAt
        updatedAt
        collections {
          items {
            id
            productName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        thumbnail {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        images {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        cad {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        spec {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        homepage {
          id
          index
          type
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          homepage {
            id
            name
            createdAt
            updatedAt
          }
        }
      }
      material {
        id
        name
        description
        createdAt
        updatedAt
        collections {
          items {
            id
            materialName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        thumbnail {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        images {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        cad {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        spec {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
      }
      aboutus {
        id
        name
        title
        subtitle
        description
        createdAt
        updatedAt
        images {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteAsset = /* GraphQL */ `
  mutation DeleteAsset(
    $input: DeleteAssetInput!
    $condition: ModelAssetConditionInput
  ) {
    deleteAsset(input: $input, condition: $condition) {
      id
      name
      url
      index
      message {
        id
        recipientContacts {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientCompanys {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientEmployees {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        toOthers
        ccOthers
        bccOthers
        subject
        body
        createdAt
        updatedAt
        attachments {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        from {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      createdAt
      updatedAt
      product {
        id
        name
        description
        year
        designer
        createdAt
        updatedAt
        collections {
          items {
            id
            productName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        thumbnail {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        images {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        cad {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        spec {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        homepage {
          id
          index
          type
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          homepage {
            id
            name
            createdAt
            updatedAt
          }
        }
      }
      material {
        id
        name
        description
        createdAt
        updatedAt
        collections {
          items {
            id
            materialName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        thumbnail {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        images {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        cad {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        spec {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
      }
      aboutus {
        id
        name
        title
        subtitle
        description
        createdAt
        updatedAt
        images {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const createHomepage = /* GraphQL */ `
  mutation CreateHomepage(
    $input: CreateHomepageInput!
    $condition: ModelHomepageConditionInput
  ) {
    createHomepage(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      items {
        items {
          id
          index
          type
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          homepage {
            id
            name
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const updateHomepage = /* GraphQL */ `
  mutation UpdateHomepage(
    $input: UpdateHomepageInput!
    $condition: ModelHomepageConditionInput
  ) {
    updateHomepage(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      items {
        items {
          id
          index
          type
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          homepage {
            id
            name
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteHomepage = /* GraphQL */ `
  mutation DeleteHomepage(
    $input: DeleteHomepageInput!
    $condition: ModelHomepageConditionInput
  ) {
    deleteHomepage(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      items {
        items {
          id
          index
          type
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          homepage {
            id
            name
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const createHomepageItem = /* GraphQL */ `
  mutation CreateHomepageItem(
    $input: CreateHomepageItemInput!
    $condition: ModelHomepageItemConditionInput
  ) {
    createHomepageItem(input: $input, condition: $condition) {
      id
      index
      type
      createdAt
      updatedAt
      product {
        id
        name
        description
        year
        designer
        createdAt
        updatedAt
        collections {
          items {
            id
            productName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        thumbnail {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        images {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        cad {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        spec {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        homepage {
          id
          index
          type
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          homepage {
            id
            name
            createdAt
            updatedAt
          }
        }
      }
      homepage {
        id
        name
        createdAt
        updatedAt
        items {
          items {
            id
            index
            type
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateHomepageItem = /* GraphQL */ `
  mutation UpdateHomepageItem(
    $input: UpdateHomepageItemInput!
    $condition: ModelHomepageItemConditionInput
  ) {
    updateHomepageItem(input: $input, condition: $condition) {
      id
      index
      type
      createdAt
      updatedAt
      product {
        id
        name
        description
        year
        designer
        createdAt
        updatedAt
        collections {
          items {
            id
            productName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        thumbnail {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        images {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        cad {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        spec {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        homepage {
          id
          index
          type
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          homepage {
            id
            name
            createdAt
            updatedAt
          }
        }
      }
      homepage {
        id
        name
        createdAt
        updatedAt
        items {
          items {
            id
            index
            type
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteHomepageItem = /* GraphQL */ `
  mutation DeleteHomepageItem(
    $input: DeleteHomepageItemInput!
    $condition: ModelHomepageItemConditionInput
  ) {
    deleteHomepageItem(input: $input, condition: $condition) {
      id
      index
      type
      createdAt
      updatedAt
      product {
        id
        name
        description
        year
        designer
        createdAt
        updatedAt
        collections {
          items {
            id
            productName
            collectionId
            createdAt
            updatedAt
          }
          nextToken
        }
        thumbnail {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        images {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        cad {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        spec {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        homepage {
          id
          index
          type
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          homepage {
            id
            name
            createdAt
            updatedAt
          }
        }
      }
      homepage {
        id
        name
        createdAt
        updatedAt
        items {
          items {
            id
            index
            type
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const createContactPage = /* GraphQL */ `
  mutation CreateContactPage(
    $input: CreateContactPageInput!
    $condition: ModelContactPageConditionInput
  ) {
    createContactPage(input: $input, condition: $condition) {
      id
      name
      email
      instagram
      sf_address
      sf_telephone
      sf_open_hours
      la_address
      la_telephone
      la_open_hours
      createdAt
      updatedAt
    }
  }
`;
export const updateContactPage = /* GraphQL */ `
  mutation UpdateContactPage(
    $input: UpdateContactPageInput!
    $condition: ModelContactPageConditionInput
  ) {
    updateContactPage(input: $input, condition: $condition) {
      id
      name
      email
      instagram
      sf_address
      sf_telephone
      sf_open_hours
      la_address
      la_telephone
      la_open_hours
      createdAt
      updatedAt
    }
  }
`;
export const deleteContactPage = /* GraphQL */ `
  mutation DeleteContactPage(
    $input: DeleteContactPageInput!
    $condition: ModelContactPageConditionInput
  ) {
    deleteContactPage(input: $input, condition: $condition) {
      id
      name
      email
      instagram
      sf_address
      sf_telephone
      sf_open_hours
      la_address
      la_telephone
      la_open_hours
      createdAt
      updatedAt
    }
  }
`;
export const createAboutus = /* GraphQL */ `
  mutation CreateAboutus(
    $input: CreateAboutusInput!
    $condition: ModelAboutusConditionInput
  ) {
    createAboutus(input: $input, condition: $condition) {
      id
      name
      title
      subtitle
      description
      createdAt
      updatedAt
      images {
        items {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const updateAboutus = /* GraphQL */ `
  mutation UpdateAboutus(
    $input: UpdateAboutusInput!
    $condition: ModelAboutusConditionInput
  ) {
    updateAboutus(input: $input, condition: $condition) {
      id
      name
      title
      subtitle
      description
      createdAt
      updatedAt
      images {
        items {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteAboutus = /* GraphQL */ `
  mutation DeleteAboutus(
    $input: DeleteAboutusInput!
    $condition: ModelAboutusConditionInput
  ) {
    deleteAboutus(input: $input, condition: $condition) {
      id
      name
      title
      subtitle
      description
      createdAt
      updatedAt
      images {
        items {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteContact = /* GraphQL */ `
  mutation DeleteContact(
    $input: DeleteContactInput!
    $condition: ModelContactConditionInput
  ) {
    deleteContact(input: $input, condition: $condition) {
      id
      type
      first_name
      last_name
      full_name
      primary_email
      primary_phone
      street_address
      city
      state
      country
      zip_code
      title
      vip
      vip_type
      createdAt
      updatedAt
      createdBy
      mailchimp_id
      sendNotification
      notes {
        items {
          id
          message
          owner
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
        }
        nextToken
      }
      contactForms {
        items {
          id
          type
          firstName
          lastName
          email
          phone
          state
          zipcode
          message
          searchFirstName
          searchLastName
          createdAt
          contactID
          updatedAt
        }
        nextToken
      }
      downloadForms {
        items {
          id
          type
          firstName
          lastName
          email
          productId
          productName
          searchFirstName
          searchLastName
          state
          zipcode
          createdAt
          contactID
          updatedAt
        }
        nextToken
      }
      submissionForms {
        items {
          id
          type
          firstName
          lastName
          email
          phone
          title
          companyName
          streetAddress
          city
          state
          zipcode
          country
          website
          fileName
          fileUrl
          message
          productId
          productName
          preferredStore
          industry
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
        }
        nextToken
      }
      tradeForms {
        items {
          id
          type
          firstName
          searchFirstName
          lastName
          searchLastName
          email
          title
          companyName
          searchCompanyName
          streetAddress
          city
          state
          zipcode
          website
          phone
          fileName
          fileUrl
          createdAt
          contactID
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          typeRecipient
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
        }
        nextToken
      }
      tasks {
        items {
          id
          title
          due_date
          description
          linkType
          creatorId
          employeeId
          status
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      pipeline {
        items {
          id
          type
          phase
          sale_amount
          createdAt
          updatedAt
          project_title
          description
          history {
            phase
            createdAt
            description
          }
          contactId
          employeeId
          searchName
          archived
          closedLostReason
          sendNotification
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      company {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      assignedTo {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      groups {
        items {
          id
          mailchimp_status
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          group {
            id
            name
            mailchimp_list_id
            mailchimp_webhook_id
            mailchimp_tag_id
            mailchimp_tag_name
            mailchimp_newsletter_id
            type
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      events {
        items {
          id
          typeAttendee
          eventAttendeeEventId
          createdAt
          updatedAt
          attendeeContact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          attendeeCompany {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          attendeeEmployee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          event {
            id
            source
            title
            startDate
            startTime
            startTimeZoneName
            startTimeZoneHour
            endDate
            endTime
            endTimeZoneName
            endTimeZoneHour
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const deletePipeline = /* GraphQL */ `
  mutation DeletePipeline(
    $input: DeletePipelineInput!
    $condition: ModelPipelineConditionInput
  ) {
    deletePipeline(input: $input, condition: $condition) {
      id
      type
      phase
      sale_amount
      createdAt
      updatedAt
      project_title
      description
      history {
        phase
        createdAt
        editedBy {
          id
          name
        }
        description
      }
      contactId
      employeeId
      searchName
      archived
      closedLostReason
      sendNotification
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      assignedTo {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const createNote = /* GraphQL */ `
  mutation CreateNote(
    $input: CreateNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    createNote(input: $input, condition: $condition) {
      id
      message
      owner
      createdAt
      updatedAt
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      company {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateNote = /* GraphQL */ `
  mutation UpdateNote(
    $input: UpdateNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    updateNote(input: $input, condition: $condition) {
      id
      message
      owner
      createdAt
      updatedAt
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      company {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteNote = /* GraphQL */ `
  mutation DeleteNote(
    $input: DeleteNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    deleteNote(input: $input, condition: $condition) {
      id
      message
      owner
      createdAt
      updatedAt
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      company {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      type
      name
      vip
      vip_type
      street_address
      city
      state
      country
      zip_code
      email
      phone
      website
      mailchimp_id
      sendNotification
      notes {
        items {
          id
          message
          owner
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
        }
        nextToken
      }
      messages {
        items {
          id
          typeRecipient
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
        }
        nextToken
      }
      tasks {
        items {
          id
          title
          due_date
          description
          linkType
          creatorId
          employeeId
          status
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      createdAt
      updatedAt
      createdBy
      contacts {
        items {
          id
          type
          first_name
          last_name
          full_name
          primary_email
          primary_phone
          street_address
          city
          state
          country
          zip_code
          title
          vip
          vip_type
          createdAt
          updatedAt
          createdBy
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          contactForms {
            nextToken
          }
          downloadForms {
            nextToken
          }
          submissionForms {
            nextToken
          }
          tradeForms {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          pipeline {
            nextToken
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        nextToken
      }
      assignedTo {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      groups {
        items {
          id
          mailchimp_status
          createdAt
          updatedAt
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          group {
            id
            name
            mailchimp_list_id
            mailchimp_webhook_id
            mailchimp_tag_id
            mailchimp_tag_name
            mailchimp_newsletter_id
            type
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      events {
        items {
          id
          typeAttendee
          eventAttendeeEventId
          createdAt
          updatedAt
          attendeeContact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          attendeeCompany {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          attendeeEmployee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          event {
            id
            source
            title
            startDate
            startTime
            startTimeZoneName
            startTimeZoneHour
            endDate
            endTime
            endTimeZoneName
            endTimeZoneHour
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const createEmployee = /* GraphQL */ `
  mutation CreateEmployee(
    $input: CreateEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    createEmployee(input: $input, condition: $condition) {
      id
      name
      jobTitle
      email
      activity {
        items {
          id
          activityId
          type
          creatorId
          createdAt
          action
          activityType
          payload {
            name
            note
            target
            targetId
            recipientOthers
            subject
            date
          }
          updatedAt
          creator {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      messagesSent {
        items {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      messagesReceived {
        items {
          id
          typeRecipient
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          employee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      tasks {
        items {
          id
          title
          due_date
          description
          linkType
          creatorId
          employeeId
          status
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      calendarPermissions {
        items {
          id
          type
          rule
          permissionUserId
          viewActive
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          calendar {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      reportFormData
      status
      createdAt
      updatedAt
      contacts {
        items {
          id
          type
          first_name
          last_name
          full_name
          primary_email
          primary_phone
          street_address
          city
          state
          country
          zip_code
          title
          vip
          vip_type
          createdAt
          updatedAt
          createdBy
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          contactForms {
            nextToken
          }
          downloadForms {
            nextToken
          }
          submissionForms {
            nextToken
          }
          tradeForms {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          pipeline {
            nextToken
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        nextToken
      }
      pipeline {
        items {
          id
          type
          phase
          sale_amount
          createdAt
          updatedAt
          project_title
          description
          history {
            phase
            createdAt
            description
          }
          contactId
          employeeId
          searchName
          archived
          closedLostReason
          sendNotification
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      companys {
        items {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        nextToken
      }
      googleCalendarToken {
        id
        service
        tokenUserId
        access_token
        refresh_token
        scope
        expiry_date
        token_type
        metadata
        createdAt
        updatedAt
        user {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      calendars {
        items {
          id
          name
          primary
          calendarUserId
          color
          googleCalendarId
          googleChannelId
          googleResourceId
          googleSyncToken
          googleName
          permissions {
            nextToken
          }
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          events {
            nextToken
          }
        }
        nextToken
      }
      events {
        items {
          id
          typeAttendee
          eventAttendeeEventId
          createdAt
          updatedAt
          attendeeContact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          attendeeCompany {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          attendeeEmployee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          event {
            id
            source
            title
            startDate
            startTime
            startTimeZoneName
            startTimeZoneHour
            endDate
            endTime
            endTimeZoneName
            endTimeZoneHour
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const updateEmployee = /* GraphQL */ `
  mutation UpdateEmployee(
    $input: UpdateEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    updateEmployee(input: $input, condition: $condition) {
      id
      name
      jobTitle
      email
      activity {
        items {
          id
          activityId
          type
          creatorId
          createdAt
          action
          activityType
          payload {
            name
            note
            target
            targetId
            recipientOthers
            subject
            date
          }
          updatedAt
          creator {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      messagesSent {
        items {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      messagesReceived {
        items {
          id
          typeRecipient
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          employee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      tasks {
        items {
          id
          title
          due_date
          description
          linkType
          creatorId
          employeeId
          status
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      calendarPermissions {
        items {
          id
          type
          rule
          permissionUserId
          viewActive
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          calendar {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      reportFormData
      status
      createdAt
      updatedAt
      contacts {
        items {
          id
          type
          first_name
          last_name
          full_name
          primary_email
          primary_phone
          street_address
          city
          state
          country
          zip_code
          title
          vip
          vip_type
          createdAt
          updatedAt
          createdBy
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          contactForms {
            nextToken
          }
          downloadForms {
            nextToken
          }
          submissionForms {
            nextToken
          }
          tradeForms {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          pipeline {
            nextToken
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        nextToken
      }
      pipeline {
        items {
          id
          type
          phase
          sale_amount
          createdAt
          updatedAt
          project_title
          description
          history {
            phase
            createdAt
            description
          }
          contactId
          employeeId
          searchName
          archived
          closedLostReason
          sendNotification
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      companys {
        items {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        nextToken
      }
      googleCalendarToken {
        id
        service
        tokenUserId
        access_token
        refresh_token
        scope
        expiry_date
        token_type
        metadata
        createdAt
        updatedAt
        user {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      calendars {
        items {
          id
          name
          primary
          calendarUserId
          color
          googleCalendarId
          googleChannelId
          googleResourceId
          googleSyncToken
          googleName
          permissions {
            nextToken
          }
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          events {
            nextToken
          }
        }
        nextToken
      }
      events {
        items {
          id
          typeAttendee
          eventAttendeeEventId
          createdAt
          updatedAt
          attendeeContact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          attendeeCompany {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          attendeeEmployee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          event {
            id
            source
            title
            startDate
            startTime
            startTimeZoneName
            startTimeZoneHour
            endDate
            endTime
            endTimeZoneName
            endTimeZoneHour
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteEmployee = /* GraphQL */ `
  mutation DeleteEmployee(
    $input: DeleteEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    deleteEmployee(input: $input, condition: $condition) {
      id
      name
      jobTitle
      email
      activity {
        items {
          id
          activityId
          type
          creatorId
          createdAt
          action
          activityType
          payload {
            name
            note
            target
            targetId
            recipientOthers
            subject
            date
          }
          updatedAt
          creator {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      messagesSent {
        items {
          id
          recipientContacts {
            nextToken
          }
          recipientCompanys {
            nextToken
          }
          recipientEmployees {
            nextToken
          }
          toOthers
          ccOthers
          bccOthers
          subject
          body
          createdAt
          updatedAt
          attachments {
            nextToken
          }
          from {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      messagesReceived {
        items {
          id
          typeRecipient
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          employee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      tasks {
        items {
          id
          title
          due_date
          description
          linkType
          creatorId
          employeeId
          status
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      calendarPermissions {
        items {
          id
          type
          rule
          permissionUserId
          viewActive
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          calendar {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      reportFormData
      status
      createdAt
      updatedAt
      contacts {
        items {
          id
          type
          first_name
          last_name
          full_name
          primary_email
          primary_phone
          street_address
          city
          state
          country
          zip_code
          title
          vip
          vip_type
          createdAt
          updatedAt
          createdBy
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          contactForms {
            nextToken
          }
          downloadForms {
            nextToken
          }
          submissionForms {
            nextToken
          }
          tradeForms {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          pipeline {
            nextToken
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        nextToken
      }
      pipeline {
        items {
          id
          type
          phase
          sale_amount
          createdAt
          updatedAt
          project_title
          description
          history {
            phase
            createdAt
            description
          }
          contactId
          employeeId
          searchName
          archived
          closedLostReason
          sendNotification
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      companys {
        items {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        nextToken
      }
      googleCalendarToken {
        id
        service
        tokenUserId
        access_token
        refresh_token
        scope
        expiry_date
        token_type
        metadata
        createdAt
        updatedAt
        user {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      calendars {
        items {
          id
          name
          primary
          calendarUserId
          color
          googleCalendarId
          googleChannelId
          googleResourceId
          googleSyncToken
          googleName
          permissions {
            nextToken
          }
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          events {
            nextToken
          }
        }
        nextToken
      }
      events {
        items {
          id
          typeAttendee
          eventAttendeeEventId
          createdAt
          updatedAt
          attendeeContact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          attendeeCompany {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          attendeeEmployee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          event {
            id
            source
            title
            startDate
            startTime
            startTimeZoneName
            startTimeZoneHour
            endDate
            endTime
            endTimeZoneName
            endTimeZoneHour
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const createToken = /* GraphQL */ `
  mutation CreateToken(
    $input: CreateTokenInput!
    $condition: ModelTokenConditionInput
  ) {
    createToken(input: $input, condition: $condition) {
      id
      service
      tokenUserId
      access_token
      refresh_token
      scope
      expiry_date
      token_type
      metadata
      createdAt
      updatedAt
      user {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateToken = /* GraphQL */ `
  mutation UpdateToken(
    $input: UpdateTokenInput!
    $condition: ModelTokenConditionInput
  ) {
    updateToken(input: $input, condition: $condition) {
      id
      service
      tokenUserId
      access_token
      refresh_token
      scope
      expiry_date
      token_type
      metadata
      createdAt
      updatedAt
      user {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteToken = /* GraphQL */ `
  mutation DeleteToken(
    $input: DeleteTokenInput!
    $condition: ModelTokenConditionInput
  ) {
    deleteToken(input: $input, condition: $condition) {
      id
      service
      tokenUserId
      access_token
      refresh_token
      scope
      expiry_date
      token_type
      metadata
      createdAt
      updatedAt
      user {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup(
    $input: DeleteGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    deleteGroup(input: $input, condition: $condition) {
      id
      name
      mailchimp_list_id
      mailchimp_webhook_id
      mailchimp_tag_id
      mailchimp_tag_name
      mailchimp_newsletter_id
      type
      createdAt
      updatedAt
      contacts {
        items {
          id
          mailchimp_status
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          group {
            id
            name
            mailchimp_list_id
            mailchimp_webhook_id
            mailchimp_tag_id
            mailchimp_tag_name
            mailchimp_newsletter_id
            type
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      companies {
        items {
          id
          mailchimp_status
          createdAt
          updatedAt
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          group {
            id
            name
            mailchimp_list_id
            mailchimp_webhook_id
            mailchimp_tag_id
            mailchimp_tag_name
            mailchimp_newsletter_id
            type
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const updateContactUsForm = /* GraphQL */ `
  mutation UpdateContactUsForm(
    $input: UpdateContactUsFormInput!
    $condition: ModelContactUsFormConditionInput
  ) {
    updateContactUsForm(input: $input, condition: $condition) {
      id
      type
      firstName
      lastName
      email
      phone
      state
      zipcode
      message
      searchFirstName
      searchLastName
      createdAt
      contactID
      updatedAt
    }
  }
`;
export const deleteContactUsForm = /* GraphQL */ `
  mutation DeleteContactUsForm(
    $input: DeleteContactUsFormInput!
    $condition: ModelContactUsFormConditionInput
  ) {
    deleteContactUsForm(input: $input, condition: $condition) {
      id
      type
      firstName
      lastName
      email
      phone
      state
      zipcode
      message
      searchFirstName
      searchLastName
      createdAt
      contactID
      updatedAt
    }
  }
`;
export const updateDownloadFileForm = /* GraphQL */ `
  mutation UpdateDownloadFileForm(
    $input: UpdateDownloadFileFormInput!
    $condition: ModelDownloadFileFormConditionInput
  ) {
    updateDownloadFileForm(input: $input, condition: $condition) {
      id
      type
      firstName
      lastName
      email
      productId
      productName
      searchFirstName
      searchLastName
      state
      zipcode
      createdAt
      contactID
      updatedAt
    }
  }
`;
export const deleteDownloadFileForm = /* GraphQL */ `
  mutation DeleteDownloadFileForm(
    $input: DeleteDownloadFileFormInput!
    $condition: ModelDownloadFileFormConditionInput
  ) {
    deleteDownloadFileForm(input: $input, condition: $condition) {
      id
      type
      firstName
      lastName
      email
      productId
      productName
      searchFirstName
      searchLastName
      state
      zipcode
      createdAt
      contactID
      updatedAt
    }
  }
`;
export const updateTradeProgramForm = /* GraphQL */ `
  mutation UpdateTradeProgramForm(
    $input: UpdateTradeProgramFormInput!
    $condition: ModelTradeProgramFormConditionInput
  ) {
    updateTradeProgramForm(input: $input, condition: $condition) {
      id
      type
      firstName
      searchFirstName
      lastName
      searchLastName
      email
      title
      companyName
      searchCompanyName
      streetAddress
      city
      state
      zipcode
      website
      phone
      fileName
      fileUrl
      createdAt
      contactID
      updatedAt
    }
  }
`;
export const deleteTradeProgramForm = /* GraphQL */ `
  mutation DeleteTradeProgramForm(
    $input: DeleteTradeProgramFormInput!
    $condition: ModelTradeProgramFormConditionInput
  ) {
    deleteTradeProgramForm(input: $input, condition: $condition) {
      id
      type
      firstName
      searchFirstName
      lastName
      searchLastName
      email
      title
      companyName
      searchCompanyName
      streetAddress
      city
      state
      zipcode
      website
      phone
      fileName
      fileUrl
      createdAt
      contactID
      updatedAt
    }
  }
`;
export const updateSubmissionForm = /* GraphQL */ `
  mutation UpdateSubmissionForm(
    $input: UpdateSubmissionFormInput!
    $condition: ModelSubmissionFormConditionInput
  ) {
    updateSubmissionForm(input: $input, condition: $condition) {
      id
      type
      firstName
      lastName
      email
      phone
      title
      companyName
      streetAddress
      city
      state
      zipcode
      country
      website
      fileName
      fileUrl
      message
      productId
      productName
      preferredStore
      industry
      createdAt
      updatedAt
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteSubmissionForm = /* GraphQL */ `
  mutation DeleteSubmissionForm(
    $input: DeleteSubmissionFormInput!
    $condition: ModelSubmissionFormConditionInput
  ) {
    deleteSubmissionForm(input: $input, condition: $condition) {
      id
      type
      firstName
      lastName
      email
      phone
      title
      companyName
      streetAddress
      city
      state
      zipcode
      country
      website
      fileName
      fileUrl
      message
      productId
      productName
      preferredStore
      industry
      createdAt
      updatedAt
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const createUserSession = /* GraphQL */ `
  mutation CreateUserSession(
    $input: CreateUserSessionInput!
    $condition: ModelUserSessionConditionInput
  ) {
    createUserSession(input: $input, condition: $condition) {
      id
      lastDownloadFormSeen
      lastContactFormSeen
      lastTradeProgramFormSeen
      lastNewsletterFormSeen
      lastAddContactFormSeen
      lastDownloadNotificationSeen
      lastContactNotificationSeen
      lastTradeProgramNotificationSeen
      lastNewsletterNotificationSeen
      lastAddContactNotificationSeen
      createdAt
      updatedAt
    }
  }
`;
export const updateUserSession = /* GraphQL */ `
  mutation UpdateUserSession(
    $input: UpdateUserSessionInput!
    $condition: ModelUserSessionConditionInput
  ) {
    updateUserSession(input: $input, condition: $condition) {
      id
      lastDownloadFormSeen
      lastContactFormSeen
      lastTradeProgramFormSeen
      lastNewsletterFormSeen
      lastAddContactFormSeen
      lastDownloadNotificationSeen
      lastContactNotificationSeen
      lastTradeProgramNotificationSeen
      lastNewsletterNotificationSeen
      lastAddContactNotificationSeen
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserSession = /* GraphQL */ `
  mutation DeleteUserSession(
    $input: DeleteUserSessionInput!
    $condition: ModelUserSessionConditionInput
  ) {
    deleteUserSession(input: $input, condition: $condition) {
      id
      lastDownloadFormSeen
      lastContactFormSeen
      lastTradeProgramFormSeen
      lastNewsletterFormSeen
      lastAddContactFormSeen
      lastDownloadNotificationSeen
      lastContactNotificationSeen
      lastTradeProgramNotificationSeen
      lastNewsletterNotificationSeen
      lastAddContactNotificationSeen
      createdAt
      updatedAt
    }
  }
`;
export const updateMailchimpConfig = /* GraphQL */ `
  mutation UpdateMailchimpConfig(
    $input: UpdateMailchimpConfigInput!
    $condition: ModelMailchimpConfigConditionInput
  ) {
    updateMailchimpConfig(input: $input, condition: $condition) {
      id
      dc
      accountname
      login_url
      api_endpoint
      access_token
      mailchimp_user_id
      mailchimp_user_email
      cognito_user_email
      createdAt
      updatedAt
    }
  }
`;
export const deleteMailchimpConfig = /* GraphQL */ `
  mutation DeleteMailchimpConfig(
    $input: DeleteMailchimpConfigInput!
    $condition: ModelMailchimpConfigConditionInput
  ) {
    deleteMailchimpConfig(input: $input, condition: $condition) {
      id
      dc
      accountname
      login_url
      api_endpoint
      access_token
      mailchimp_user_id
      mailchimp_user_email
      cognito_user_email
      createdAt
      updatedAt
    }
  }
`;
export const updateActivity = /* GraphQL */ `
  mutation UpdateActivity(
    $input: UpdateActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    updateActivity(input: $input, condition: $condition) {
      id
      activityId
      type
      creatorId
      createdAt
      action
      activityType
      payload {
        name
        note
        target
        targetId
        recipientContacts {
          id
          name
        }
        recipientCompanys {
          id
          name
        }
        attendeeContacts {
          id
          name
        }
        attendeeEmployees {
          id
          name
        }
        attendeeCompanys {
          id
          name
        }
        recipientOthers
        subject
        date
      }
      updatedAt
      creator {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteActivity = /* GraphQL */ `
  mutation DeleteActivity(
    $input: DeleteActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    deleteActivity(input: $input, condition: $condition) {
      id
      activityId
      type
      creatorId
      createdAt
      action
      activityType
      payload {
        name
        note
        target
        targetId
        recipientContacts {
          id
          name
        }
        recipientCompanys {
          id
          name
        }
        attendeeContacts {
          id
          name
        }
        attendeeEmployees {
          id
          name
        }
        attendeeCompanys {
          id
          name
        }
        recipientOthers
        subject
        date
      }
      updatedAt
      creator {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteGmailConfig = /* GraphQL */ `
  mutation DeleteGmailConfig(
    $input: DeleteGmailConfigInput!
    $condition: ModelGmailConfigConditionInput
  ) {
    deleteGmailConfig(input: $input, condition: $condition) {
      id
      cognito_user_email
      gmail_address
      access_token
      refresh_token
      scope
      token_type
      expiry_date
      signature
      createdAt
      updatedAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      recipientContacts {
        items {
          id
          typeRecipient
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
        }
        nextToken
      }
      recipientCompanys {
        items {
          id
          typeRecipient
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
        }
        nextToken
      }
      recipientEmployees {
        items {
          id
          typeRecipient
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          employee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      toOthers
      ccOthers
      bccOthers
      subject
      body
      createdAt
      updatedAt
      attachments {
        items {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      from {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      recipientContacts {
        items {
          id
          typeRecipient
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
        }
        nextToken
      }
      recipientCompanys {
        items {
          id
          typeRecipient
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
        }
        nextToken
      }
      recipientEmployees {
        items {
          id
          typeRecipient
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          employee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      toOthers
      ccOthers
      bccOthers
      subject
      body
      createdAt
      updatedAt
      attachments {
        items {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      from {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      recipientContacts {
        items {
          id
          typeRecipient
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
        }
        nextToken
      }
      recipientCompanys {
        items {
          id
          typeRecipient
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
        }
        nextToken
      }
      recipientEmployees {
        items {
          id
          typeRecipient
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          employee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      toOthers
      ccOthers
      bccOthers
      subject
      body
      createdAt
      updatedAt
      attachments {
        items {
          id
          name
          url
          index
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          product {
            id
            name
            description
            year
            designer
            createdAt
            updatedAt
          }
          material {
            id
            name
            description
            createdAt
            updatedAt
          }
          aboutus {
            id
            name
            title
            subtitle
            description
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      from {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const createMessageContact = /* GraphQL */ `
  mutation CreateMessageContact(
    $input: CreateMessageContactInput!
    $condition: ModelMessageContactConditionInput
  ) {
    createMessageContact(input: $input, condition: $condition) {
      id
      typeRecipient
      message {
        id
        recipientContacts {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientCompanys {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientEmployees {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        toOthers
        ccOthers
        bccOthers
        subject
        body
        createdAt
        updatedAt
        attachments {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        from {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      createdAt
      updatedAt
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateMessageContact = /* GraphQL */ `
  mutation UpdateMessageContact(
    $input: UpdateMessageContactInput!
    $condition: ModelMessageContactConditionInput
  ) {
    updateMessageContact(input: $input, condition: $condition) {
      id
      typeRecipient
      message {
        id
        recipientContacts {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientCompanys {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientEmployees {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        toOthers
        ccOthers
        bccOthers
        subject
        body
        createdAt
        updatedAt
        attachments {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        from {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      createdAt
      updatedAt
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteMessageContact = /* GraphQL */ `
  mutation DeleteMessageContact(
    $input: DeleteMessageContactInput!
    $condition: ModelMessageContactConditionInput
  ) {
    deleteMessageContact(input: $input, condition: $condition) {
      id
      typeRecipient
      message {
        id
        recipientContacts {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientCompanys {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientEmployees {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        toOthers
        ccOthers
        bccOthers
        subject
        body
        createdAt
        updatedAt
        attachments {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        from {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      createdAt
      updatedAt
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const createMessageCompany = /* GraphQL */ `
  mutation CreateMessageCompany(
    $input: CreateMessageCompanyInput!
    $condition: ModelMessageCompanyConditionInput
  ) {
    createMessageCompany(input: $input, condition: $condition) {
      id
      typeRecipient
      message {
        id
        recipientContacts {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientCompanys {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientEmployees {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        toOthers
        ccOthers
        bccOthers
        subject
        body
        createdAt
        updatedAt
        attachments {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        from {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      createdAt
      updatedAt
      company {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateMessageCompany = /* GraphQL */ `
  mutation UpdateMessageCompany(
    $input: UpdateMessageCompanyInput!
    $condition: ModelMessageCompanyConditionInput
  ) {
    updateMessageCompany(input: $input, condition: $condition) {
      id
      typeRecipient
      message {
        id
        recipientContacts {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientCompanys {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientEmployees {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        toOthers
        ccOthers
        bccOthers
        subject
        body
        createdAt
        updatedAt
        attachments {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        from {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      createdAt
      updatedAt
      company {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteMessageCompany = /* GraphQL */ `
  mutation DeleteMessageCompany(
    $input: DeleteMessageCompanyInput!
    $condition: ModelMessageCompanyConditionInput
  ) {
    deleteMessageCompany(input: $input, condition: $condition) {
      id
      typeRecipient
      message {
        id
        recipientContacts {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientCompanys {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientEmployees {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        toOthers
        ccOthers
        bccOthers
        subject
        body
        createdAt
        updatedAt
        attachments {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        from {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      createdAt
      updatedAt
      company {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const createMessageEmployee = /* GraphQL */ `
  mutation CreateMessageEmployee(
    $input: CreateMessageEmployeeInput!
    $condition: ModelMessageEmployeeConditionInput
  ) {
    createMessageEmployee(input: $input, condition: $condition) {
      id
      typeRecipient
      message {
        id
        recipientContacts {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientCompanys {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientEmployees {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        toOthers
        ccOthers
        bccOthers
        subject
        body
        createdAt
        updatedAt
        attachments {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        from {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      createdAt
      updatedAt
      employee {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateMessageEmployee = /* GraphQL */ `
  mutation UpdateMessageEmployee(
    $input: UpdateMessageEmployeeInput!
    $condition: ModelMessageEmployeeConditionInput
  ) {
    updateMessageEmployee(input: $input, condition: $condition) {
      id
      typeRecipient
      message {
        id
        recipientContacts {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientCompanys {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientEmployees {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        toOthers
        ccOthers
        bccOthers
        subject
        body
        createdAt
        updatedAt
        attachments {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        from {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      createdAt
      updatedAt
      employee {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteMessageEmployee = /* GraphQL */ `
  mutation DeleteMessageEmployee(
    $input: DeleteMessageEmployeeInput!
    $condition: ModelMessageEmployeeConditionInput
  ) {
    deleteMessageEmployee(input: $input, condition: $condition) {
      id
      typeRecipient
      message {
        id
        recipientContacts {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientCompanys {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        recipientEmployees {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        toOthers
        ccOthers
        bccOthers
        subject
        body
        createdAt
        updatedAt
        attachments {
          items {
            id
            name
            url
            index
            createdAt
            updatedAt
          }
          nextToken
        }
        from {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
      }
      createdAt
      updatedAt
      employee {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const createCalendar = /* GraphQL */ `
  mutation CreateCalendar(
    $input: CreateCalendarInput!
    $condition: ModelCalendarConditionInput
  ) {
    createCalendar(input: $input, condition: $condition) {
      id
      name
      primary
      calendarUserId
      color
      googleCalendarId
      googleChannelId
      googleResourceId
      googleSyncToken
      googleName
      permissions {
        items {
          id
          type
          rule
          permissionUserId
          viewActive
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          calendar {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      createdAt
      updatedAt
      user {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      events {
        items {
          id
          source
          title
          startDate
          startTime
          startTimeZoneName
          startTimeZoneHour
          endDate
          endTime
          endTimeZoneName
          endTimeZoneHour
          allDay
          location
          description
          creatorId
          eventCalendarId
          googleEventId
          createdAt
          updatedAt
          calendar {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          attendees {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteCalendar = /* GraphQL */ `
  mutation DeleteCalendar(
    $input: DeleteCalendarInput!
    $condition: ModelCalendarConditionInput
  ) {
    deleteCalendar(input: $input, condition: $condition) {
      id
      name
      primary
      calendarUserId
      color
      googleCalendarId
      googleChannelId
      googleResourceId
      googleSyncToken
      googleName
      permissions {
        items {
          id
          type
          rule
          permissionUserId
          viewActive
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          calendar {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      createdAt
      updatedAt
      user {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      events {
        items {
          id
          source
          title
          startDate
          startTime
          startTimeZoneName
          startTimeZoneHour
          endDate
          endTime
          endTimeZoneName
          endTimeZoneHour
          allDay
          location
          description
          creatorId
          eventCalendarId
          googleEventId
          createdAt
          updatedAt
          calendar {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          attendees {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const createPermission = /* GraphQL */ `
  mutation CreatePermission(
    $input: CreatePermissionInput!
    $condition: ModelPermissionConditionInput
  ) {
    createPermission(input: $input, condition: $condition) {
      id
      type
      rule
      permissionUserId
      viewActive
      createdAt
      updatedAt
      user {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      calendar {
        id
        name
        primary
        calendarUserId
        color
        googleCalendarId
        googleChannelId
        googleResourceId
        googleSyncToken
        googleName
        permissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        user {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        events {
          items {
            id
            source
            title
            startDate
            startTime
            startTimeZoneName
            startTimeZoneHour
            endDate
            endTime
            endTimeZoneName
            endTimeZoneHour
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updatePermission = /* GraphQL */ `
  mutation UpdatePermission(
    $input: UpdatePermissionInput!
    $condition: ModelPermissionConditionInput
  ) {
    updatePermission(input: $input, condition: $condition) {
      id
      type
      rule
      permissionUserId
      viewActive
      createdAt
      updatedAt
      user {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      calendar {
        id
        name
        primary
        calendarUserId
        color
        googleCalendarId
        googleChannelId
        googleResourceId
        googleSyncToken
        googleName
        permissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        user {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        events {
          items {
            id
            source
            title
            startDate
            startTime
            startTimeZoneName
            startTimeZoneHour
            endDate
            endTime
            endTimeZoneName
            endTimeZoneHour
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deletePermission = /* GraphQL */ `
  mutation DeletePermission(
    $input: DeletePermissionInput!
    $condition: ModelPermissionConditionInput
  ) {
    deletePermission(input: $input, condition: $condition) {
      id
      type
      rule
      permissionUserId
      viewActive
      createdAt
      updatedAt
      user {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      calendar {
        id
        name
        primary
        calendarUserId
        color
        googleCalendarId
        googleChannelId
        googleResourceId
        googleSyncToken
        googleName
        permissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        user {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        events {
          items {
            id
            source
            title
            startDate
            startTime
            startTimeZoneName
            startTimeZoneHour
            endDate
            endTime
            endTimeZoneName
            endTimeZoneHour
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const createTask = /* GraphQL */ `
  mutation CreateTask(
    $input: CreateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    createTask(input: $input, condition: $condition) {
      id
      title
      due_date
      description
      linkType
      creatorId
      employeeId
      status
      createdAt
      updatedAt
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      company {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      assignedTo {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateTask = /* GraphQL */ `
  mutation UpdateTask(
    $input: UpdateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    updateTask(input: $input, condition: $condition) {
      id
      title
      due_date
      description
      linkType
      creatorId
      employeeId
      status
      createdAt
      updatedAt
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      company {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      assignedTo {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteTask = /* GraphQL */ `
  mutation DeleteTask(
    $input: DeleteTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    deleteTask(input: $input, condition: $condition) {
      id
      title
      due_date
      description
      linkType
      creatorId
      employeeId
      status
      createdAt
      updatedAt
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      company {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      assignedTo {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateAnalytics = /* GraphQL */ `
  mutation UpdateAnalytics(
    $input: UpdateAnalyticsInput!
    $condition: ModelAnalyticsConditionInput
  ) {
    updateAnalytics(input: $input, condition: $condition) {
      id
      createdAt
      siteVisit
      topProduct
      updatedAt
    }
  }
`;
export const deleteAnalytics = /* GraphQL */ `
  mutation DeleteAnalytics(
    $input: DeleteAnalyticsInput!
    $condition: ModelAnalyticsConditionInput
  ) {
    deleteAnalytics(input: $input, condition: $condition) {
      id
      createdAt
      siteVisit
      topProduct
      updatedAt
    }
  }
`;
export const createContact = /* GraphQL */ `
  mutation CreateContact(
    $input: CreateContactInput!
    $condition: ModelContactConditionInput
  ) {
    createContact(input: $input, condition: $condition) {
      id
      type
      first_name
      last_name
      full_name
      primary_email
      primary_phone
      street_address
      city
      state
      country
      zip_code
      title
      vip
      vip_type
      createdAt
      updatedAt
      createdBy
      mailchimp_id
      sendNotification
      notes {
        items {
          id
          message
          owner
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
        }
        nextToken
      }
      contactForms {
        items {
          id
          type
          firstName
          lastName
          email
          phone
          state
          zipcode
          message
          searchFirstName
          searchLastName
          createdAt
          contactID
          updatedAt
        }
        nextToken
      }
      downloadForms {
        items {
          id
          type
          firstName
          lastName
          email
          productId
          productName
          searchFirstName
          searchLastName
          state
          zipcode
          createdAt
          contactID
          updatedAt
        }
        nextToken
      }
      submissionForms {
        items {
          id
          type
          firstName
          lastName
          email
          phone
          title
          companyName
          streetAddress
          city
          state
          zipcode
          country
          website
          fileName
          fileUrl
          message
          productId
          productName
          preferredStore
          industry
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
        }
        nextToken
      }
      tradeForms {
        items {
          id
          type
          firstName
          searchFirstName
          lastName
          searchLastName
          email
          title
          companyName
          searchCompanyName
          streetAddress
          city
          state
          zipcode
          website
          phone
          fileName
          fileUrl
          createdAt
          contactID
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          typeRecipient
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
        }
        nextToken
      }
      tasks {
        items {
          id
          title
          due_date
          description
          linkType
          creatorId
          employeeId
          status
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      pipeline {
        items {
          id
          type
          phase
          sale_amount
          createdAt
          updatedAt
          project_title
          description
          history {
            phase
            createdAt
            description
          }
          contactId
          employeeId
          searchName
          archived
          closedLostReason
          sendNotification
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      company {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      assignedTo {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      groups {
        items {
          id
          mailchimp_status
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          group {
            id
            name
            mailchimp_list_id
            mailchimp_webhook_id
            mailchimp_tag_id
            mailchimp_tag_name
            mailchimp_newsletter_id
            type
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      events {
        items {
          id
          typeAttendee
          eventAttendeeEventId
          createdAt
          updatedAt
          attendeeContact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          attendeeCompany {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          attendeeEmployee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          event {
            id
            source
            title
            startDate
            startTime
            startTimeZoneName
            startTimeZoneHour
            endDate
            endTime
            endTimeZoneName
            endTimeZoneHour
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const updateContact = /* GraphQL */ `
  mutation UpdateContact(
    $input: UpdateContactInput!
    $condition: ModelContactConditionInput
  ) {
    updateContact(input: $input, condition: $condition) {
      id
      type
      first_name
      last_name
      full_name
      primary_email
      primary_phone
      street_address
      city
      state
      country
      zip_code
      title
      vip
      vip_type
      createdAt
      updatedAt
      createdBy
      mailchimp_id
      sendNotification
      notes {
        items {
          id
          message
          owner
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
        }
        nextToken
      }
      contactForms {
        items {
          id
          type
          firstName
          lastName
          email
          phone
          state
          zipcode
          message
          searchFirstName
          searchLastName
          createdAt
          contactID
          updatedAt
        }
        nextToken
      }
      downloadForms {
        items {
          id
          type
          firstName
          lastName
          email
          productId
          productName
          searchFirstName
          searchLastName
          state
          zipcode
          createdAt
          contactID
          updatedAt
        }
        nextToken
      }
      submissionForms {
        items {
          id
          type
          firstName
          lastName
          email
          phone
          title
          companyName
          streetAddress
          city
          state
          zipcode
          country
          website
          fileName
          fileUrl
          message
          productId
          productName
          preferredStore
          industry
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
        }
        nextToken
      }
      tradeForms {
        items {
          id
          type
          firstName
          searchFirstName
          lastName
          searchLastName
          email
          title
          companyName
          searchCompanyName
          streetAddress
          city
          state
          zipcode
          website
          phone
          fileName
          fileUrl
          createdAt
          contactID
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          typeRecipient
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
        }
        nextToken
      }
      tasks {
        items {
          id
          title
          due_date
          description
          linkType
          creatorId
          employeeId
          status
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      pipeline {
        items {
          id
          type
          phase
          sale_amount
          createdAt
          updatedAt
          project_title
          description
          history {
            phase
            createdAt
            description
          }
          contactId
          employeeId
          searchName
          archived
          closedLostReason
          sendNotification
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      company {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      assignedTo {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      groups {
        items {
          id
          mailchimp_status
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          group {
            id
            name
            mailchimp_list_id
            mailchimp_webhook_id
            mailchimp_tag_id
            mailchimp_tag_name
            mailchimp_newsletter_id
            type
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      events {
        items {
          id
          typeAttendee
          eventAttendeeEventId
          createdAt
          updatedAt
          attendeeContact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          attendeeCompany {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          attendeeEmployee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          event {
            id
            source
            title
            startDate
            startTime
            startTimeZoneName
            startTimeZoneHour
            endDate
            endTime
            endTimeZoneName
            endTimeZoneHour
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const createPipeline = /* GraphQL */ `
  mutation CreatePipeline(
    $input: CreatePipelineInput!
    $condition: ModelPipelineConditionInput
  ) {
    createPipeline(input: $input, condition: $condition) {
      id
      type
      phase
      sale_amount
      createdAt
      updatedAt
      project_title
      description
      history {
        phase
        createdAt
        editedBy {
          id
          name
        }
        description
      }
      contactId
      employeeId
      searchName
      archived
      closedLostReason
      sendNotification
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      assignedTo {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updatePipeline = /* GraphQL */ `
  mutation UpdatePipeline(
    $input: UpdatePipelineInput!
    $condition: ModelPipelineConditionInput
  ) {
    updatePipeline(input: $input, condition: $condition) {
      id
      type
      phase
      sale_amount
      createdAt
      updatedAt
      project_title
      description
      history {
        phase
        createdAt
        editedBy {
          id
          name
        }
        description
      }
      contactId
      employeeId
      searchName
      archived
      closedLostReason
      sendNotification
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      assignedTo {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      type
      name
      vip
      vip_type
      street_address
      city
      state
      country
      zip_code
      email
      phone
      website
      mailchimp_id
      sendNotification
      notes {
        items {
          id
          message
          owner
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
        }
        nextToken
      }
      messages {
        items {
          id
          typeRecipient
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
        }
        nextToken
      }
      tasks {
        items {
          id
          title
          due_date
          description
          linkType
          creatorId
          employeeId
          status
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      createdAt
      updatedAt
      createdBy
      contacts {
        items {
          id
          type
          first_name
          last_name
          full_name
          primary_email
          primary_phone
          street_address
          city
          state
          country
          zip_code
          title
          vip
          vip_type
          createdAt
          updatedAt
          createdBy
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          contactForms {
            nextToken
          }
          downloadForms {
            nextToken
          }
          submissionForms {
            nextToken
          }
          tradeForms {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          pipeline {
            nextToken
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        nextToken
      }
      assignedTo {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      groups {
        items {
          id
          mailchimp_status
          createdAt
          updatedAt
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          group {
            id
            name
            mailchimp_list_id
            mailchimp_webhook_id
            mailchimp_tag_id
            mailchimp_tag_name
            mailchimp_newsletter_id
            type
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      events {
        items {
          id
          typeAttendee
          eventAttendeeEventId
          createdAt
          updatedAt
          attendeeContact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          attendeeCompany {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          attendeeEmployee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          event {
            id
            source
            title
            startDate
            startTime
            startTimeZoneName
            startTimeZoneHour
            endDate
            endTime
            endTimeZoneName
            endTimeZoneHour
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      type
      name
      vip
      vip_type
      street_address
      city
      state
      country
      zip_code
      email
      phone
      website
      mailchimp_id
      sendNotification
      notes {
        items {
          id
          message
          owner
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
        }
        nextToken
      }
      messages {
        items {
          id
          typeRecipient
          message {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
        }
        nextToken
      }
      tasks {
        items {
          id
          title
          due_date
          description
          linkType
          creatorId
          employeeId
          status
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      createdAt
      updatedAt
      createdBy
      contacts {
        items {
          id
          type
          first_name
          last_name
          full_name
          primary_email
          primary_phone
          street_address
          city
          state
          country
          zip_code
          title
          vip
          vip_type
          createdAt
          updatedAt
          createdBy
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          contactForms {
            nextToken
          }
          downloadForms {
            nextToken
          }
          submissionForms {
            nextToken
          }
          tradeForms {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          pipeline {
            nextToken
          }
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        nextToken
      }
      assignedTo {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      groups {
        items {
          id
          mailchimp_status
          createdAt
          updatedAt
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          group {
            id
            name
            mailchimp_list_id
            mailchimp_webhook_id
            mailchimp_tag_id
            mailchimp_tag_name
            mailchimp_newsletter_id
            type
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      events {
        items {
          id
          typeAttendee
          eventAttendeeEventId
          createdAt
          updatedAt
          attendeeContact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          attendeeCompany {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          attendeeEmployee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          event {
            id
            source
            title
            startDate
            startTime
            startTimeZoneName
            startTimeZoneHour
            endDate
            endTime
            endTimeZoneName
            endTimeZoneHour
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const createGroupContact = /* GraphQL */ `
  mutation CreateGroupContact(
    $input: CreateGroupContactInput!
    $condition: ModelGroupContactConditionInput
  ) {
    createGroupContact(input: $input, condition: $condition) {
      id
      mailchimp_status
      createdAt
      updatedAt
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      group {
        id
        name
        mailchimp_list_id
        mailchimp_webhook_id
        mailchimp_tag_id
        mailchimp_tag_name
        mailchimp_newsletter_id
        type
        createdAt
        updatedAt
        contacts {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        companies {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateGroupContact = /* GraphQL */ `
  mutation UpdateGroupContact(
    $input: UpdateGroupContactInput!
    $condition: ModelGroupContactConditionInput
  ) {
    updateGroupContact(input: $input, condition: $condition) {
      id
      mailchimp_status
      createdAt
      updatedAt
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      group {
        id
        name
        mailchimp_list_id
        mailchimp_webhook_id
        mailchimp_tag_id
        mailchimp_tag_name
        mailchimp_newsletter_id
        type
        createdAt
        updatedAt
        contacts {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        companies {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteGroupContact = /* GraphQL */ `
  mutation DeleteGroupContact(
    $input: DeleteGroupContactInput!
    $condition: ModelGroupContactConditionInput
  ) {
    deleteGroupContact(input: $input, condition: $condition) {
      id
      mailchimp_status
      createdAt
      updatedAt
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      group {
        id
        name
        mailchimp_list_id
        mailchimp_webhook_id
        mailchimp_tag_id
        mailchimp_tag_name
        mailchimp_newsletter_id
        type
        createdAt
        updatedAt
        contacts {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        companies {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const createGroupCompany = /* GraphQL */ `
  mutation CreateGroupCompany(
    $input: CreateGroupCompanyInput!
    $condition: ModelGroupCompanyConditionInput
  ) {
    createGroupCompany(input: $input, condition: $condition) {
      id
      mailchimp_status
      createdAt
      updatedAt
      company {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      group {
        id
        name
        mailchimp_list_id
        mailchimp_webhook_id
        mailchimp_tag_id
        mailchimp_tag_name
        mailchimp_newsletter_id
        type
        createdAt
        updatedAt
        contacts {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        companies {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateGroupCompany = /* GraphQL */ `
  mutation UpdateGroupCompany(
    $input: UpdateGroupCompanyInput!
    $condition: ModelGroupCompanyConditionInput
  ) {
    updateGroupCompany(input: $input, condition: $condition) {
      id
      mailchimp_status
      createdAt
      updatedAt
      company {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      group {
        id
        name
        mailchimp_list_id
        mailchimp_webhook_id
        mailchimp_tag_id
        mailchimp_tag_name
        mailchimp_newsletter_id
        type
        createdAt
        updatedAt
        contacts {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        companies {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteGroupCompany = /* GraphQL */ `
  mutation DeleteGroupCompany(
    $input: DeleteGroupCompanyInput!
    $condition: ModelGroupCompanyConditionInput
  ) {
    deleteGroupCompany(input: $input, condition: $condition) {
      id
      mailchimp_status
      createdAt
      updatedAt
      company {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      group {
        id
        name
        mailchimp_list_id
        mailchimp_webhook_id
        mailchimp_tag_id
        mailchimp_tag_name
        mailchimp_newsletter_id
        type
        createdAt
        updatedAt
        contacts {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        companies {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup(
    $input: CreateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    createGroup(input: $input, condition: $condition) {
      id
      name
      mailchimp_list_id
      mailchimp_webhook_id
      mailchimp_tag_id
      mailchimp_tag_name
      mailchimp_newsletter_id
      type
      createdAt
      updatedAt
      contacts {
        items {
          id
          mailchimp_status
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          group {
            id
            name
            mailchimp_list_id
            mailchimp_webhook_id
            mailchimp_tag_id
            mailchimp_tag_name
            mailchimp_newsletter_id
            type
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      companies {
        items {
          id
          mailchimp_status
          createdAt
          updatedAt
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          group {
            id
            name
            mailchimp_list_id
            mailchimp_webhook_id
            mailchimp_tag_id
            mailchimp_tag_name
            mailchimp_newsletter_id
            type
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup(
    $input: UpdateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    updateGroup(input: $input, condition: $condition) {
      id
      name
      mailchimp_list_id
      mailchimp_webhook_id
      mailchimp_tag_id
      mailchimp_tag_name
      mailchimp_newsletter_id
      type
      createdAt
      updatedAt
      contacts {
        items {
          id
          mailchimp_status
          createdAt
          updatedAt
          contact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          group {
            id
            name
            mailchimp_list_id
            mailchimp_webhook_id
            mailchimp_tag_id
            mailchimp_tag_name
            mailchimp_newsletter_id
            type
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      companies {
        items {
          id
          mailchimp_status
          createdAt
          updatedAt
          company {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          group {
            id
            name
            mailchimp_list_id
            mailchimp_webhook_id
            mailchimp_tag_id
            mailchimp_tag_name
            mailchimp_newsletter_id
            type
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const createContactUsForm = /* GraphQL */ `
  mutation CreateContactUsForm(
    $input: CreateContactUsFormInput!
    $condition: ModelContactUsFormConditionInput
  ) {
    createContactUsForm(input: $input, condition: $condition) {
      id
      type
      firstName
      lastName
      email
      phone
      state
      zipcode
      message
      searchFirstName
      searchLastName
      createdAt
      contactID
      updatedAt
    }
  }
`;
export const createDownloadFileForm = /* GraphQL */ `
  mutation CreateDownloadFileForm(
    $input: CreateDownloadFileFormInput!
    $condition: ModelDownloadFileFormConditionInput
  ) {
    createDownloadFileForm(input: $input, condition: $condition) {
      id
      type
      firstName
      lastName
      email
      productId
      productName
      searchFirstName
      searchLastName
      state
      zipcode
      createdAt
      contactID
      updatedAt
    }
  }
`;
export const createTradeProgramForm = /* GraphQL */ `
  mutation CreateTradeProgramForm(
    $input: CreateTradeProgramFormInput!
    $condition: ModelTradeProgramFormConditionInput
  ) {
    createTradeProgramForm(input: $input, condition: $condition) {
      id
      type
      firstName
      searchFirstName
      lastName
      searchLastName
      email
      title
      companyName
      searchCompanyName
      streetAddress
      city
      state
      zipcode
      website
      phone
      fileName
      fileUrl
      createdAt
      contactID
      updatedAt
    }
  }
`;
export const createSubmissionForm = /* GraphQL */ `
  mutation CreateSubmissionForm(
    $input: CreateSubmissionFormInput!
    $condition: ModelSubmissionFormConditionInput
  ) {
    createSubmissionForm(input: $input, condition: $condition) {
      id
      type
      firstName
      lastName
      email
      phone
      title
      companyName
      streetAddress
      city
      state
      zipcode
      country
      website
      fileName
      fileUrl
      message
      productId
      productName
      preferredStore
      industry
      createdAt
      updatedAt
      contact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const createMailchimpConfig = /* GraphQL */ `
  mutation CreateMailchimpConfig(
    $input: CreateMailchimpConfigInput!
    $condition: ModelMailchimpConfigConditionInput
  ) {
    createMailchimpConfig(input: $input, condition: $condition) {
      id
      dc
      accountname
      login_url
      api_endpoint
      access_token
      mailchimp_user_id
      mailchimp_user_email
      cognito_user_email
      createdAt
      updatedAt
    }
  }
`;
export const createActivity = /* GraphQL */ `
  mutation CreateActivity(
    $input: CreateActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    createActivity(input: $input, condition: $condition) {
      id
      activityId
      type
      creatorId
      createdAt
      action
      activityType
      payload {
        name
        note
        target
        targetId
        recipientContacts {
          id
          name
        }
        recipientCompanys {
          id
          name
        }
        attendeeContacts {
          id
          name
        }
        attendeeEmployees {
          id
          name
        }
        attendeeCompanys {
          id
          name
        }
        recipientOthers
        subject
        date
      }
      updatedAt
      creator {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const createGmailConfig = /* GraphQL */ `
  mutation CreateGmailConfig(
    $input: CreateGmailConfigInput!
    $condition: ModelGmailConfigConditionInput
  ) {
    createGmailConfig(input: $input, condition: $condition) {
      id
      cognito_user_email
      gmail_address
      access_token
      refresh_token
      scope
      token_type
      expiry_date
      signature
      createdAt
      updatedAt
    }
  }
`;
export const updateGmailConfig = /* GraphQL */ `
  mutation UpdateGmailConfig(
    $input: UpdateGmailConfigInput!
    $condition: ModelGmailConfigConditionInput
  ) {
    updateGmailConfig(input: $input, condition: $condition) {
      id
      cognito_user_email
      gmail_address
      access_token
      refresh_token
      scope
      token_type
      expiry_date
      signature
      createdAt
      updatedAt
    }
  }
`;
export const updateCalendar = /* GraphQL */ `
  mutation UpdateCalendar(
    $input: UpdateCalendarInput!
    $condition: ModelCalendarConditionInput
  ) {
    updateCalendar(input: $input, condition: $condition) {
      id
      name
      primary
      calendarUserId
      color
      googleCalendarId
      googleChannelId
      googleResourceId
      googleSyncToken
      googleName
      permissions {
        items {
          id
          type
          rule
          permissionUserId
          viewActive
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          calendar {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      createdAt
      updatedAt
      user {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      events {
        items {
          id
          source
          title
          startDate
          startTime
          startTimeZoneName
          startTimeZoneHour
          endDate
          endTime
          endTimeZoneName
          endTimeZoneHour
          allDay
          location
          description
          creatorId
          eventCalendarId
          googleEventId
          createdAt
          updatedAt
          calendar {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          attendees {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      source
      title
      startDate
      startTime
      startTimeZoneName
      startTimeZoneHour
      endDate
      endTime
      endTimeZoneName
      endTimeZoneHour
      allDay
      location
      description
      creatorId
      eventCalendarId
      googleEventId
      createdAt
      updatedAt
      calendar {
        id
        name
        primary
        calendarUserId
        color
        googleCalendarId
        googleChannelId
        googleResourceId
        googleSyncToken
        googleName
        permissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        user {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        events {
          items {
            id
            source
            title
            startDate
            startTime
            startTimeZoneName
            startTimeZoneHour
            endDate
            endTime
            endTimeZoneName
            endTimeZoneHour
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      attendees {
        items {
          id
          typeAttendee
          eventAttendeeEventId
          createdAt
          updatedAt
          attendeeContact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          attendeeCompany {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          attendeeEmployee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          event {
            id
            source
            title
            startDate
            startTime
            startTimeZoneName
            startTimeZoneHour
            endDate
            endTime
            endTimeZoneName
            endTimeZoneHour
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      source
      title
      startDate
      startTime
      startTimeZoneName
      startTimeZoneHour
      endDate
      endTime
      endTimeZoneName
      endTimeZoneHour
      allDay
      location
      description
      creatorId
      eventCalendarId
      googleEventId
      createdAt
      updatedAt
      calendar {
        id
        name
        primary
        calendarUserId
        color
        googleCalendarId
        googleChannelId
        googleResourceId
        googleSyncToken
        googleName
        permissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        user {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        events {
          items {
            id
            source
            title
            startDate
            startTime
            startTimeZoneName
            startTimeZoneHour
            endDate
            endTime
            endTimeZoneName
            endTimeZoneHour
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      attendees {
        items {
          id
          typeAttendee
          eventAttendeeEventId
          createdAt
          updatedAt
          attendeeContact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          attendeeCompany {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          attendeeEmployee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          event {
            id
            source
            title
            startDate
            startTime
            startTimeZoneName
            startTimeZoneHour
            endDate
            endTime
            endTimeZoneName
            endTimeZoneHour
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      source
      title
      startDate
      startTime
      startTimeZoneName
      startTimeZoneHour
      endDate
      endTime
      endTimeZoneName
      endTimeZoneHour
      allDay
      location
      description
      creatorId
      eventCalendarId
      googleEventId
      createdAt
      updatedAt
      calendar {
        id
        name
        primary
        calendarUserId
        color
        googleCalendarId
        googleChannelId
        googleResourceId
        googleSyncToken
        googleName
        permissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        user {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        events {
          items {
            id
            source
            title
            startDate
            startTime
            startTimeZoneName
            startTimeZoneHour
            endDate
            endTime
            endTimeZoneName
            endTimeZoneHour
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      attendees {
        items {
          id
          typeAttendee
          eventAttendeeEventId
          createdAt
          updatedAt
          attendeeContact {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          attendeeCompany {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          attendeeEmployee {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          event {
            id
            source
            title
            startDate
            startTime
            startTimeZoneName
            startTimeZoneHour
            endDate
            endTime
            endTimeZoneName
            endTimeZoneHour
            allDay
            location
            description
            creatorId
            eventCalendarId
            googleEventId
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const createEventAttendee = /* GraphQL */ `
  mutation CreateEventAttendee(
    $input: CreateEventAttendeeInput!
    $condition: ModelEventAttendeeConditionInput
  ) {
    createEventAttendee(input: $input, condition: $condition) {
      id
      typeAttendee
      eventAttendeeEventId
      createdAt
      updatedAt
      attendeeContact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      attendeeCompany {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      attendeeEmployee {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      event {
        id
        source
        title
        startDate
        startTime
        startTimeZoneName
        startTimeZoneHour
        endDate
        endTime
        endTimeZoneName
        endTimeZoneHour
        allDay
        location
        description
        creatorId
        eventCalendarId
        googleEventId
        createdAt
        updatedAt
        calendar {
          id
          name
          primary
          calendarUserId
          color
          googleCalendarId
          googleChannelId
          googleResourceId
          googleSyncToken
          googleName
          permissions {
            nextToken
          }
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          events {
            nextToken
          }
        }
        attendees {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateEventAttendee = /* GraphQL */ `
  mutation UpdateEventAttendee(
    $input: UpdateEventAttendeeInput!
    $condition: ModelEventAttendeeConditionInput
  ) {
    updateEventAttendee(input: $input, condition: $condition) {
      id
      typeAttendee
      eventAttendeeEventId
      createdAt
      updatedAt
      attendeeContact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      attendeeCompany {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      attendeeEmployee {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      event {
        id
        source
        title
        startDate
        startTime
        startTimeZoneName
        startTimeZoneHour
        endDate
        endTime
        endTimeZoneName
        endTimeZoneHour
        allDay
        location
        description
        creatorId
        eventCalendarId
        googleEventId
        createdAt
        updatedAt
        calendar {
          id
          name
          primary
          calendarUserId
          color
          googleCalendarId
          googleChannelId
          googleResourceId
          googleSyncToken
          googleName
          permissions {
            nextToken
          }
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          events {
            nextToken
          }
        }
        attendees {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteEventAttendee = /* GraphQL */ `
  mutation DeleteEventAttendee(
    $input: DeleteEventAttendeeInput!
    $condition: ModelEventAttendeeConditionInput
  ) {
    deleteEventAttendee(input: $input, condition: $condition) {
      id
      typeAttendee
      eventAttendeeEventId
      createdAt
      updatedAt
      attendeeContact {
        id
        type
        first_name
        last_name
        full_name
        primary_email
        primary_phone
        street_address
        city
        state
        country
        zip_code
        title
        vip
        vip_type
        createdAt
        updatedAt
        createdBy
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        contactForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            state
            zipcode
            message
            searchFirstName
            searchLastName
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        downloadForms {
          items {
            id
            type
            firstName
            lastName
            email
            productId
            productName
            searchFirstName
            searchLastName
            state
            zipcode
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        submissionForms {
          items {
            id
            type
            firstName
            lastName
            email
            phone
            title
            companyName
            streetAddress
            city
            state
            zipcode
            country
            website
            fileName
            fileUrl
            message
            productId
            productName
            preferredStore
            industry
            createdAt
            updatedAt
          }
          nextToken
        }
        tradeForms {
          items {
            id
            type
            firstName
            searchFirstName
            lastName
            searchLastName
            email
            title
            companyName
            searchCompanyName
            streetAddress
            city
            state
            zipcode
            website
            phone
            fileName
            fileUrl
            createdAt
            contactID
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        company {
          id
          type
          name
          vip
          vip_type
          street_address
          city
          state
          country
          zip_code
          email
          phone
          website
          mailchimp_id
          sendNotification
          notes {
            nextToken
          }
          messages {
            nextToken
          }
          tasks {
            nextToken
          }
          createdAt
          updatedAt
          createdBy
          contacts {
            nextToken
          }
          assignedTo {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          groups {
            nextToken
          }
          events {
            nextToken
          }
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      attendeeCompany {
        id
        type
        name
        vip
        vip_type
        street_address
        city
        state
        country
        zip_code
        email
        phone
        website
        mailchimp_id
        sendNotification
        notes {
          items {
            id
            message
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        createdBy
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        assignedTo {
          id
          name
          jobTitle
          email
          activity {
            nextToken
          }
          messagesSent {
            nextToken
          }
          messagesReceived {
            nextToken
          }
          tasks {
            nextToken
          }
          calendarPermissions {
            nextToken
          }
          reportFormData
          status
          createdAt
          updatedAt
          contacts {
            nextToken
          }
          pipeline {
            nextToken
          }
          companys {
            nextToken
          }
          googleCalendarToken {
            id
            service
            tokenUserId
            access_token
            refresh_token
            scope
            expiry_date
            token_type
            metadata
            createdAt
            updatedAt
          }
          calendars {
            nextToken
          }
          events {
            nextToken
          }
        }
        groups {
          items {
            id
            mailchimp_status
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      attendeeEmployee {
        id
        name
        jobTitle
        email
        activity {
          items {
            id
            activityId
            type
            creatorId
            createdAt
            action
            activityType
            updatedAt
          }
          nextToken
        }
        messagesSent {
          items {
            id
            toOthers
            ccOthers
            bccOthers
            subject
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        messagesReceived {
          items {
            id
            typeRecipient
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            title
            due_date
            description
            linkType
            creatorId
            employeeId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        calendarPermissions {
          items {
            id
            type
            rule
            permissionUserId
            viewActive
            createdAt
            updatedAt
          }
          nextToken
        }
        reportFormData
        status
        createdAt
        updatedAt
        contacts {
          items {
            id
            type
            first_name
            last_name
            full_name
            primary_email
            primary_phone
            street_address
            city
            state
            country
            zip_code
            title
            vip
            vip_type
            createdAt
            updatedAt
            createdBy
            mailchimp_id
            sendNotification
          }
          nextToken
        }
        pipeline {
          items {
            id
            type
            phase
            sale_amount
            createdAt
            updatedAt
            project_title
            description
            contactId
            employeeId
            searchName
            archived
            closedLostReason
            sendNotification
          }
          nextToken
        }
        companys {
          items {
            id
            type
            name
            vip
            vip_type
            street_address
            city
            state
            country
            zip_code
            email
            phone
            website
            mailchimp_id
            sendNotification
            createdAt
            updatedAt
            createdBy
          }
          nextToken
        }
        googleCalendarToken {
          id
          service
          tokenUserId
          access_token
          refresh_token
          scope
          expiry_date
          token_type
          metadata
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
        }
        calendars {
          items {
            id
            name
            primary
            calendarUserId
            color
            googleCalendarId
            googleChannelId
            googleResourceId
            googleSyncToken
            googleName
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      event {
        id
        source
        title
        startDate
        startTime
        startTimeZoneName
        startTimeZoneHour
        endDate
        endTime
        endTimeZoneName
        endTimeZoneHour
        allDay
        location
        description
        creatorId
        eventCalendarId
        googleEventId
        createdAt
        updatedAt
        calendar {
          id
          name
          primary
          calendarUserId
          color
          googleCalendarId
          googleChannelId
          googleResourceId
          googleSyncToken
          googleName
          permissions {
            nextToken
          }
          createdAt
          updatedAt
          user {
            id
            name
            jobTitle
            email
            reportFormData
            status
            createdAt
            updatedAt
          }
          events {
            nextToken
          }
        }
        attendees {
          items {
            id
            typeAttendee
            eventAttendeeEventId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const createAnalytics = /* GraphQL */ `
  mutation CreateAnalytics(
    $input: CreateAnalyticsInput!
    $condition: ModelAnalyticsConditionInput
  ) {
    createAnalytics(input: $input, condition: $condition) {
      id
      createdAt
      siteVisit
      topProduct
      updatedAt
    }
  }
`;
